import { type HTMLAttributes } from 'react'

import { PageContainerWrapper } from '@/components/shared/page-container/styled'

type PageContainerProps = HTMLAttributes<HTMLDivElement> & {
  //TODO: Import type from component library
  as?: string | React.ComponentType<unknown>
  /**
   * Bottom container margin
   */
  bottom?: string
  /**
   * Top container margin
   */
  top?: string
  /**
   * Condenses the max-width for desktop views and adds more gutter on tablet
   */
  isCondensed?: boolean
}

/**
 * Container to wrap all pages of application.
 * It automatically centralizes components in the max-width.
 */

export const PageContainer = ({
  bottom = '3rem',
  isCondensed = false,
  top = '3rem',
  ...rest
}: PageContainerProps) => {
  return <PageContainerWrapper $isCondensed={isCondensed} $bottom={bottom} $top={top} {...rest} />
}
