import { useDecision } from '@optimizely/react-sdk'
import { type FC, type PropsWithChildren, useRef, useState } from 'react'

import { ClientRender } from '@/components/shared/client-render'
import { ErrorBoundary } from '@/components/shared/error-boundary'
import { Logo } from '@/components/shared/global-header/Logo'
import { SearchBar } from '@/components/shared/global-header/search-bar'
import { SearchBarContainer } from '@/components/shared/global-header/search-bar/styled'
import {
  Container,
  GlobalHeaderContainer,
  GlobalHeaderWrapper,
  LogoWrapper,
  Wrapper,
} from '@/components/shared/global-header/styled'
import { Tools } from '@/components/shared/global-header/tools'
import { GlobalMessages } from '@/components/shared/global-messages'
import { usePSABanner } from '@/components/shared/psa-banner/usePSABanner'
import { type PSAKeys } from '@/constants/psaBanner'
import { useClickOutside } from '@/hooks/useClickOutside'
import { FEATURE_FLAG } from '@/lib/optimizely'

export type GlobalHeaderProps = PropsWithChildren & {
  /**
   * Will show the search bar in the header. Defaults to false.
   */
  showSearchBar: boolean
  psaCampaign?: PSAKeys
  pageName?: string
}

export const GlobalHeader: FC<GlobalHeaderProps> = ({
  psaCampaign,
  showSearchBar = false,
  children,
  pageName,
}) => {
  const [decision] = useDecision(FEATURE_FLAG.HEADER_SIMPLIFIED)
  const isHeaderSimplified = decision?.enabled === true && decision?.variationKey === 'b'

  const [isMobileHeaderExpanded, toggleMobileHeader] = useState<boolean>(false)
  const globalHeader = useRef<HTMLElement>(null)

  const { isVisible: isPSAVisible } = usePSABanner(psaCampaign)

  const handleClickOut = () => {
    toggleMobileHeader(false)
  }

  useClickOutside(globalHeader, () => handleClickOut)

  return (
    <GlobalHeaderWrapper>
      <Container
        ref={globalHeader}
        id="global-header"
        hasChildren={!!children}
        isPSABannerVisible={isPSAVisible}
        pageName={pageName}
      >
        <GlobalHeaderContainer>
          <ClientRender>
            <Wrapper isHeaderSimplified={isHeaderSimplified}>
              <LogoWrapper data-testid="global-header-logo">
                <Logo />
              </LogoWrapper>

              <ErrorBoundary fingerprintId="GlobalHeader">
                {showSearchBar && (
                  <SearchBarContainer
                    showSearchBar={showSearchBar}
                    data-testid="global-search-bar"
                    isHeaderSimplified={isHeaderSimplified}
                  >
                    <SearchBar
                      isMobileHeaderExpanded={isMobileHeaderExpanded}
                      toggleMobileHeader={toggleMobileHeader}
                    />
                  </SearchBarContainer>
                )}
              </ErrorBoundary>

              <Tools showPostAdCTA={showSearchBar} />
            </Wrapper>
          </ClientRender>

          {children}
        </GlobalHeaderContainer>
      </Container>

      <GlobalMessages />
    </GlobalHeaderWrapper>
  )
}
