import styled from 'styled-components'

import { MAX_SEARCH_FORM_WIDTH, SEARCH_BAR_SECOND_LINE_BREAK } from '@/constants/others'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { Button } from '@/ui/atoms/button'
import { TextLink } from '@/ui/atoms/text-link'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const SearchBarContainer = styled.div<{
  showSearchBar?: boolean
  isHeaderSimplified?: boolean
}>`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  order: 3;
  width: 100%;
  z-index: ${zIndexRegistry.SEARCH_BAR};

  ${({ showSearchBar, isHeaderSimplified }) =>
    !isHeaderSimplified && showSearchBar
      ? `
      min-height: 5rem;
      max-width: 100%;
  `
      : `
      min-height: unset;
      max-width: unset;
  `}

  ${({ theme }) => MEDIA_QUERIES(theme).large} {
    order: ${({ isHeaderSimplified }) => (isHeaderSimplified ? 2 : 3)};
  }

  ${SEARCH_BAR_SECOND_LINE_BREAK} {
    align-items: center;
    flex-direction: row;
    order: 2;

    ${({ showSearchBar, isHeaderSimplified }) =>
      !isHeaderSimplified && showSearchBar
        ? `
        min-height: 5.1rem;
        max-width: 80rem;
    `
        : `
        min-height: unset;
        max-width: unset;
    `}
  }
`

type SearchFieldContainerProps = {
  isHeaderSimplified: boolean
}

export const SearchFieldContainer = styled.form<SearchFieldContainerProps>(
  ({ theme, isHeaderSimplified }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.default};
  margin: ${isHeaderSimplified ? `0` : `${theme.spacing.default} 0`};
  max-height: 11.7rem;
  overflow: visible;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    border-radius: ${theme.spacing.mini};
    border: 0.1rem solid ${theme.colors.grey.light3};
    flex-direction: row;
    gap: 0;
    height: 5.1rem;
  }

  ${SEARCH_BAR_SECOND_LINE_BREAK} {
    max-width: ${MAX_SEARCH_FORM_WIDTH};
    margin: 0;
  }
  `
)

export const SearchFieldWrapper = styled.div(
  ({ theme }) => `
  width: 100%;
  height: 5.1rem;

  ${MEDIA_QUERIES(theme).medium} {
    // TODO: revisit dropdown component in the UI library to remove nested div selection
    & > div {
      margin-top: -.1rem;
    }

    & > div > div > div > div {
      border: none;
    }
  }
`
)

export const Divider = styled.div(
  ({ theme }) => `
  display: none;

  ${MEDIA_QUERIES(theme).medium} {
    background-color: ${theme.colors.grey.light3};
    display: block;
    flex-shrink: 0;
    margin: ${theme.spacing.defaultSmall} 0;
    width: 0.1rem;
  }
`
)

export const ButtonWrapper = styled.div(
  ({ theme }) => `
  display: none;

  ${MEDIA_QUERIES(theme).medium} {
    margin-top: -0.1rem;
    margin-right: -0.1rem;
    display: flex;

    & > button {
      height: calc(100% + .1rem);
      min-width: 13rem
    }
  }
`
)

export const LocationContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-shrink: 0;
  margin: 0;

  > svg {
    color: ${theme.colors.purple.primary};
    height: ${theme.spacing.large};
    width: ${theme.spacing.large};
  }

  ${MEDIA_QUERIES(theme).large} {
    margin-left: ${theme.spacing.default};
  }
`
)

// NOTE: If this button is used after experiment, it will need to be updated in our UI library
export const SearchButton = styled(Button)(
  ({ theme }) => `
    padding: ${theme.spacing.defaultSmall};
    background-color: ${theme.colors.purple.primary};

  & svg {
    fill: ${theme.colors.purple.primary};
  }

  & > svg circle {
    stroke: ${theme.colors.white};
  }

  & > svg path {
    fill: ${theme.colors.white};
  }

  &:hover {
    background-color: ${theme.colors.purple.light1};
  }

  &:focus {
    background-color: ${theme.colors.purple.dark1};
  }
  `
)

export const LocationButton = styled(TextLink)(
  ({ theme }) => `
  align-items: center;
  display: flex;
  flex-shrink: 0;

  ${MEDIA_QUERIES(theme).medium} {
    margin-left: ${theme.spacing.default};
  }
`
)

export const LocationIconContainer = styled.div(
  ({ theme }) => `
  height: 2.4rem;
  width: 2.4rem;

  & > svg {
    color: ${theme.colors.purple.primary};
    height: 2.4rem;
    width: 2.4rem;
  }
`
)

type LocationNameProps = {
  isHeaderSimplified: boolean
}

export const LocationName = styled.div<LocationNameProps>(
  ({ theme, isHeaderSimplified }) => `
  flex-shrink: 0;
  max-width: ${isHeaderSimplified ? '0rem' : '11rem'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: max-width 0.3s ease-in-out;
  margin-left: ${isHeaderSimplified ? '0' : theme.spacing.defaultSmall};

  ${MEDIA_QUERIES(theme).small} {
    max-width: ${isHeaderSimplified ? '0' : '11rem'};
  }

  ${MEDIA_QUERIES(theme).large} {
    max-width: 11rem;
  }
`
)

export const LocationRadius = styled.div<{ isHeaderSimplified: boolean }>(
  ({ theme, isHeaderSimplified }) => `
  white-space: nowrap;
  display: ${isHeaderSimplified ? 'none' : 'block'};
  padding-left: ${theme.spacing.defaultSmall};

  ${MEDIA_QUERIES(theme).medium} {
    display: block;
  }
`
)

type MobileSearchBarProps = {
  isMobileHeaderExpanded: boolean
}

export const MobileButtonContainer = styled.div<MobileSearchBarProps>(
  ({ theme, isMobileHeaderExpanded }) => `
  border-top: 0.1rem solid ${theme.colors.grey.light3};
  margin: ${theme.spacing.default} -${theme.spacing.default} -${theme.spacing.default} -${
    theme.spacing.default
  };
  padding: ${theme.spacing.default};
  display: ${isMobileHeaderExpanded ? 'block' : 'none'};

  ${MEDIA_QUERIES(theme).medium} {
    display: none;
  }
  `
)
