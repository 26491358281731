import { useDecision } from '@optimizely/react-sdk'
import dynamic from 'next/dynamic'
import { type FC } from 'react'

import { ClientRender } from '@/components/shared/client-render'
import {
  LocationButton,
  LocationIconContainer,
  LocationName,
  LocationRadius,
} from '@/components/shared/global-header/search-bar/styled'
import { SearchLocationModal } from '@/components/shared/search-location-modal'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_CTA_HEADER } from '@/lib/ga/constants/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { FEATURE_FLAG } from '@/lib/optimizely'

const LocationOutlineIcon = dynamic(() => import('@kijiji/icons/src/icons/LocationOutline'), {
  ssr: false,
})

type SearchLocationProps = {
  id: string
}

export const SearchLocation: FC<SearchLocationProps> = ({ id }) => {
  const { location } = useGetLocation()
  const { apiLocale } = useLocale()

  const [decision] = useDecision(FEATURE_FLAG.HEADER_SIMPLIFIED)
  const isHeaderSimplified = decision?.enabled === true && decision?.variationKey === 'b'

  const handleLocationClick = (openModal: () => void) => {
    trackEvent({ action: GA_EVENT.LocationSelectBegin, label: GA_CTA_HEADER })
    openModal()
  }

  /**
   * It should use location name if is a region, or address with fallback on the name if is not an exact location.
   */
  const locationName = location.isRegion
    ? location.name[apiLocale]
    : location.area?.address || location.name[apiLocale]

  return (
    <SearchLocationModal>
      {(openModal) => (
        <LocationButton
          data-testid={id}
          forwardedAs="button"
          onClick={() => handleLocationClick(openModal)}
          size="medium"
          variant="secondary"
        >
          <LocationIconContainer>
            <LocationOutlineIcon aria-hidden="true" />
          </LocationIconContainer>

          <ClientRender>
            <LocationName data-testid="location-name" isHeaderSimplified={isHeaderSimplified}>
              {locationName}
            </LocationName>
          </ClientRender>

          {!location.isRegion && !!location.area && (
            <ClientRender>
              <LocationRadius data-testid="location-radius" isHeaderSimplified={isHeaderSimplified}>
                {` • `}
                {location.area.radius}km
              </LocationRadius>
            </ClientRender>
          )}
        </LocationButton>
      )}
    </SearchLocationModal>
  )
}
