import { useDecision } from '@optimizely/react-sdk'
import { type FC } from 'react'

import { ClientRender } from '@/components/shared/client-render'
import { ErrorBoundary } from '@/components/shared/error-boundary'
import { PostAdCTA } from '@/components/shared/global-header/PostAdCTA'
import { SearchLocation } from '@/components/shared/global-header/search-bar/SearchLocation'
import { LoggedInLinks } from '@/components/shared/global-header/tools/logged-in-links'
import { LoggedOutLinks } from '@/components/shared/global-header/tools/logged-out-links'
import { SessionMenu } from '@/components/shared/global-header/tools/session-menu'
import { ToolsContainer } from '@/components/shared/global-header/tools/styled'
import { useSession } from '@/hooks/useSession'
import { FEATURE_FLAG } from '@/lib/optimizely'

type ToolsProps = {
  showPostAdCTA: boolean
}

export const Tools: FC<ToolsProps> = ({ showPostAdCTA }) => {
  const { optimisticIsAuthenticated } = useSession()

  const [decision] = useDecision(FEATURE_FLAG.HEADER_SIMPLIFIED)
  const isHeaderSimplified = decision?.enabled === true && decision?.variationKey === 'b'

  return (
    <ClientRender>
      <ToolsContainer data-testid="global-header-tools" isHeaderSimplified={isHeaderSimplified}>
        <ErrorBoundary fingerprintId="Tools">
          {isHeaderSimplified ? (
            <ClientRender>
              <SearchLocation id="set-search-range-header" />
            </ClientRender>
          ) : null}
          {optimisticIsAuthenticated ? (
            <>
              <LoggedInLinks />
              <SessionMenu />
            </>
          ) : (
            <LoggedOutLinks />
          )}
        </ErrorBoundary>

        {showPostAdCTA && <PostAdCTA />}
      </ToolsContainer>
    </ClientRender>
  )
}
